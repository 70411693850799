import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Contact extends Component {
  render() {
    return (
      <>
        <Header />

        {/* <!--Search Popup--> */}
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>Contact Us</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li className="active">Contact</li>
            </ul>
          </div>
        </section>
        {/* <section className="map-section">
          <div className="map-column">
            <GoogleMaps
              apiKey={"AIzaSyBQ5y0EF8dE6qwc03FcbXHJfXr4vEa7z54"}
              style={{ height: "800px", width: "100%" }}
              zoom={12}
              center={{ lat: 37.4224764, lng: -122.0842499 }}
              markers={{ lat: 37.4224764, lng: -122.0842499 }} //optional
            />
          </div>
        </section> */}

        {/* <!-- Contact Section Two --> */}
        <section className="contact-section-two mt-5">
          <div className="auto-container mt-5">
            <div className="contact-info-area mt-5">
              <div className="contact-info mt-5">
                <div className="row">
                  <div className="info-column col-lg-4">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-email-6"></span>
                      </div>
                      <h3>Email Address</h3>
                      <ul>
                        <li>
                          <Link
                            style={{
                              color: "blue",
                            }}
                            to={"/mailto:freeman@bridgemarktech.com"}
                          >
                            freeman@bridgemarktech.com
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="info-column col-lg-4">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-call-1"></span>
                      </div>
                      <h3>Phone Number</h3>
                      <ul>
                        <li>
                          <Link
                            style={{
                              color: "blue",
                            }}
                            to={"/tel:+917204320381"}
                          >
                            +91 7204320381
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="info-column col-lg-4">
                    <div className="icon-box">
                      <div className="icon">
                        <span className="flaticon-location"></span>
                      </div>
                      <h3>Office Address</h3>
                      <ul>
                        <li>
                          <a
                            href="https://www.google.com/maps/place/12%C2%B055'54.6%22N+77%C2%B033'20.2%22E/@12.9318314,77.5530434,17z/data=!3m1!4b1!4m4!3m3!8m2!3d12.9318314!4d77.5556183?entry=ttu"
                            target="blank"
                            style={{
                              color: "blue",
                            }}
                          >
                            <address>
                              #172 , BridgeMark , Banashakari 3rd Stafe , ITI
                              layout , Katriguppe Vidya pita near Indian Oil
                              Petrol Bunk,
                              <br />
                              Bangalore , Karnataka
                              <br />
                            </address>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-area">
              <div className="sec-title text-center">
                <div className="sub-title">Write Here</div>
                <h2>Get In Touch</h2>
              </div>
              {/* <!-- Contact Form--> */}
              <div className="contact-form text-center">
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfEbOMrOeiGH0Y5QtAYeSSVDmW7Xgo0ILxyDJFSZufw-ue_vg/viewform?embedded=true"
                  width="100%"
                  height="1000"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  title="Bridgemark"
                >
                  Loading…
                </iframe>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default Contact;
