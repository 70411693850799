import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class ForIndianOwnersSlug extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slugData: {
        "indian-subsidiary-company": {
          label: "Indian Subsidiary Company",
          index: 0,
          discription:
            "<p>A foreign company can set up a Private Limited Company as its subsidiary in India. The minimum requirements for incorporating a company in India are</p><p>It should have a minimum of 2 directors and 2 shareholders. One of directors should be an Indian resident. Finding an Indian resident director becomes a challenge for many foreign companies. Generally, the companies appoint the senior employee or the country manager as its director.</p><p>The incorporation documents including the address proof, identity proof, etc of the foreign shareholders and directors to be notarized and Apostilled/ endorsed at the Indian Consulate in the country where the registered office of the entity is situated</p>",
        },
        "private-limited-company": {
          label: "Private Limited Company Registration",
          index: 0,
          discription:
            "<p>Setting up a Private Limited Company in India requires a minimum of TWO shareholders and TWO directors. The shareholders can be the directors of the company. One of the directors, however, should be a Resident Indian.</p><p>Choosing the name of the company is a bit tricky! While choosing the name, one has to ensure that the Name</p><p>Does not resemble the name of any other already registered company</p><p>Does not violate the provisions of emblems and names (Prevention of Improper Use Act, 1950) by availing the services of checking name availability on the portal</p><p>Select, in order of preference, at least one suitable name upto a maximum of six names, indicative of the main objects of the company</p><p>Once the name is chosen, then file an application for the incorporation with Registrar of Companies (ROC), along with Memorandum and Articles of Association, particulars of directors, etc.</p><p>Digital Signature &ndash; All the directors have to mandatorily obtain the digital signature.</p><p>Once the application is processed by the Registrar of Companies, they will issue Incorporation Certificate mentioning a Corporate Identity Number (CIN)</p>",
        },
        "one-person-company": {
          label: "One Person Company",
          index: 0,
          discription:
            '<p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">A One Person Company (OPC) combines most of the benefits of a sole proprietorship and a company form of business. It has only one person as a member who will act in the capacity of a director as well as a shareholder.</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">OPC is formed as a Private Limited Company.</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">+ Can a foreign national form an OPC?</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">No. Only an Indian citizen and resident can form a One Person Company</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">+ Is there a restriction in setting up OPC?</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">Yes. An individual can start only one OPC</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">+ Who can start an OPC?</p><p style="text-align: left;color: rgb(40, 40, 40);background-color: rgb(255, 255, 255);font-size: 16px;border: none;">Only a natural person who is an Indian citizen and resident in India shall be eligible to act as a member and nominee of an OPC.</p>',
        },
        "limited-liability-partnerships": {
          label: "Limited Liability Partnership Registration",
          index: 0,
          discription:
            "<p>LLP is an alternative corporate business form that gives the benefits of limited liability of a company and the flexibility of a partnership.</p><p>The LLP is a separate legal entity, is liable to the full extent of its assets but the liability of the partners is limited to their agreed contribution in the LLP. Further, no partner is liable on account of the independent or un-authorized actions of other partners, thus individual partners are shielded from joint liability created by another partner&rsquo;s wrongful business decisions or misconduct.</p>",
        },
      },
      slug: "",
    };
  }

  componentDidMount() {
    this.setState((prev) => ({
      ...prev,
      slug: this.props.match.params.slug,
    }));
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.setState((prev) => ({
        ...prev,
        slug: this.props.match.params.slug,
      }));
    }
  }

  render() {
    return (
      <>
        <Header />
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1> For Indian Owners</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li className="active">
                {this.state?.slugData[this.state?.slug]?.label}
              </li>
            </ul>
          </div>
        </section>
        <section className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div
                className="my-5"
                dangerouslySetInnerHTML={{
                  __html: this.state?.slugData[this.state?.slug]?.discription,
                }}
              />
              ;
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default withRouter(ForIndianOwnersSlug);
