import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer3 extends Component {
    render() {
        return (
            <>
                {/* <!-- Main Footer --> */}
                <footer className="main-footer">
                    <div className="auto-container">
                        {/* <!--Widgets Section--> */}
                        <div className="widgets-section">
                            <div className="row clearfix">
                                
                                {/* <!--Column--> */}
                                <div className="column col-lg-4">
                                    <div className="footer-widget logo-widget">
                                        <div className="widget-content">
                                            <div className="footer-logo">
                                                <Link to={'/#'}><img className="" src={require('../../assets/images/footer-logo.png')} alt="" /></Link>
                                            </div>
                                            <div className="text">تنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. تنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                            <ul className="social-links clearfix">
                                                <li><Link to={'/#'}><span className="fab fa-facebook-f"></span></Link></li>
                                                <li><Link to={'/#'}><span className="fab fa-twitter"></span></Link></li>
                                                <li><Link to={'/#'}><span className="fab fa-vimeo-v"></span></Link></li>
                                                <li><Link to={'/#'}><span className="fab fa-instagram"></span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* <!--Column--> */}
                                <div className="column col-lg-4">
                                    <div className="footer-widget links-widget">
                                        <div className="widget-content">
                                            <h3>الروابط  </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li><Link to={'/#'}>الصفحة الرئيسية </Link></li>
                                                        <li><Link to={'/#'}>حول  </Link></li>
                                                        <li><Link to={'/#'}>خدمات  </Link></li>
                                                        <li><Link to={'/#'}>محفظة  </Link></li>
                                                        <li><Link to={'/#'}>التسعير  </Link></li>
                                                        <li><Link to={'/#'}>اتصل  </Link></li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li><Link to={'/#'}>أخبار</Link></li>
                                                        <li><Link to={'/#'}>خبر صحفى  </Link></li>
                                                        <li><Link to={'/#'}>دراسة الحالة</Link></li>
                                                        <li><Link to={'/#'}>شروط  </Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                                
                                {/* <!--Column--> */}
                                <div className="column col-lg-4">
                                    <div className="footer-widget instagram-widget">
                                        <h3>معرض الصور  </h3>
                                        <div className="inner-box">
                                        <div className="wrapper-box">
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-1.jpg')} alt="" />
                                                    
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-1.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-2.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-2.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-3.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-3.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-4.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-4.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-5.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-5.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-6.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-6.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-7.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-7.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-8.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-8.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div className="image">
                                                    <img className="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-9.jpg')} alt="" />
                                                    <div className="overlay-link"><Link to={'/assets/images/gallery/gallery-9.jpg'} className="lightbox-image" data-fancybox="gallery"><span className="fa fa-plus"></span></Link></div>
                                                </div>
                                            </div>
                                            {/* <!-- /.gallery-wrapper --> */}
                                        </div>	
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    
                    {/* <!-- Footer Bottom --> */}
                    <div className="auto-container">				
                        <div className="footer-bottom">
                            <div className="copyright">حقوق الطبع ل  <Link to={'/#'}>Webtend</Link> - 2020</div>
                        </div>
                    </div>
                </footer>


            </>
        );
    }
}

export default Footer3;