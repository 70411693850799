import React, { Component } from "react";
const banner = require("./../../assets/images/main-slider/banner.jpg");
class HomeSlider1 extends Component {
  render() {
    return (
        <>
        {/* <div className="m-5"></div> */}
        <img src={banner} alt="Bridgemark Banner" />

        </>

    );
  }
}
export default HomeSlider1;