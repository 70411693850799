import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Service extends Component {
  renderservicer = () => {
    let data = [
      {
        label: "International Taxation",
        key: "internationalTaxation",
        index: 0,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Involves taxation of income that crosses international borders.",
          "Ensures fair distribution of tax liabilities among countries.",
        ],
      },
      {
        label: "Registrations",
        key: "registrations",
        index: 1,
        img: "",
        type: "html",
        htmlData:
          "<p>Incorporation of the company or firms is a first step in commencing a business in India. Post-incorporation of the entity, it has to get the registrations done under various statues.</p><p><br></p><p><strong>Below is the list of registrations to be done by business entities</strong></p><ol><li>Shops and Establishment</li><li>Professional Tax (PT)</li><li>Trade License</li><li>Employee Provident Fund (EPF)</li><li>Employee State Insurance (ESI)</li><li>Goods and Service Tax (GST)</li><li>Import Export Code (IEC)</li><li>STPI and Non STPI</li><li>MSME or Udyog Aadhar</li><li>Trade Marks</li><li>FSSAI (Food License)</li></ol>",
        discription: [],
      },
      {
        label: "NRI services",
        key: "nriServices",
        index: 1,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "We have been rendering Non Resident Indian (NRI) Services in for the last three decades.",
          "As per our experience, Non Resident Indians often need the following services, specially tax matters related to sale of property.",
        ],
      },

      {
        label: "Foreign Transfers",
        key: "foreignTransfers",
        index: 2,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Refers to the movement of funds or assets across international borders",
        ],
      },
      {
        label: "Advance Rulings under DTAA",
        key: "advanceRulingsUnderDTAA",
        index: 3,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Advance rulings provide clarity on the tax implications of cross-border transactions under Double Taxation Avoidance Agreements (DTAA)",
        ],
      },
      {
        label: "DTAA US & Korea",
        key: "dtaaUsAndKorea",
        index: 4,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Specific agreements between India and the United States, and India and Korea, to avoid double taxation",
        ],
      },
      {
        label: "FLA Returns",
        key: "flaReturns",
        index: 5,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Annual returns to be filed by Indian companies that have received foreign direct investment (FDI) and/or made overseas investments.",
        ],
      },
      {
        label: "Form 15CB & 15CA",
        key: "form15cbAnd15ca",
        index: 6,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Form 15CB is for certifying payments to non-residents, and Form 15CA is for furnishing information regarding such payments",
        ],
      },
      {
        label: "RBI Returns",
        key: "rbiReturns",
        index: 7,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Returns to be filed with the Reserve Bank of India (RBI) for certain foreign exchange transactions",
        ],
      },
      {
        label: "Transfer Pricing Audit",
        key: "transferPricingAudit",
        index: 8,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          " Ensures that transactions between related entities are priced appropriately to prevent tax evasion",
        ],
      },
      {
        label: "NRI Individual Taxation",
        key: "nriIndividualTaxation",
        index: 9,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Taxation rules applicable to Non-Resident Indians (NRIs) on their income in India",
        ],
      },
      {
        label: "TDS Rates Applicability",
        key: "tdsRatesApplicability",
        index: 10,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Applicable rates for Tax Deducted at Source (TDS) on various types of payments",
        ],
      },
      {
        label: "Royalty Applicability",
        key: "royaltyApplicability",
        index: 11,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Deals with the taxation of royalty payments, especially in cross-border transactions.",
        ],
      },
      {
        label: "Foreign Capital Transfer Process",
        key: "foreignCapitalTransferProcess",
        index: 12,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "The process involved in transferring foreign capital in and out of a country",
        ],
      },
      {
        label: "IEC Code",
        key: "iecCode",
        index: 13,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Importer Exporter Code, required for businesses engaged in international trade",
        ],
      },
      {
        label: "LUT Code",
        key: "lutCode",
        index: 14,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Letter of Undertaking for exporters to export goods or services without payment of GST",
        ],
      },
      {
        label: "Foreign Direct Investment",
        key: "foreignDirectInvestment",
        index: 15,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Investment made by a person or entity from one country into business interests located in another country",
        ],
      },
      {
        label: "DGFT Compliance",
        key: "dgftCompliance",
        index: 16,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Compliance with regulations and policies of the Directorate General of Foreign Trade",
        ],
      },
      {
        label: "Customs ICEGATE Compliance",
        key: "customsIcegateCompliance",
        index: 17,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Compliance with electronic data interchange system for customs, known as ICEGATE",
        ],
      },
      {
        label: "Company Formation",
        key: "companyFormation",
        index: 18,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Private Limited Incorporation",
          "LLP Formation",
          "Partnership Firm Registration",
          "Sole Proprietorship"
        ],
      },
      {
        label: "License & Registration",
        key: "licenseAndRegistration",
        index: 19,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "PAN",
          "TAN",
          "Labour – Shops & Establishment License",
          "FSSAI License",
          "GST & LUT",
          "Provident fund",
          "ESIC",
          "Profession Tax",
          "Udyam/MSME Registration",
          "IEC Code",
          "Contract Labour License",
          "TradeMark Registration",
          "Trade License",
          "Factory License",
        ],
      },
      {
        label: "Returns Filings",
        key: "",
        index: 20,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "GSTR1 & GSTR3B Returns",
          "TDS Returns",
          "Provident Fund Returns",
          "ESIC Returns",
          "Profession Tax Returns",
          "Income Tax Returns",
          "MSME Returns",
          "FLA Returns",
          "Labour Annual Returns"
        ],
      },
      {
        label: "Payroll Services",
        key: "",
        index: 21,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Salary Register Computation",
          "Payslips Generation",
          "Appointment Letter",
          "Offer Letter",
          "Experience Letter",
          "PF, ESIC & PT Compliance",
        ],
      },
      {
        label: "Other Services",
        key: "",
        index: 22,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "MIS Reports ",
          "Internal Audits ",
          "Lower Deduction Certificates",
          "Notice handling",
          "Profitability & Ration Analysis",
          "Project wise costing report"
        ],
      },
      {
        label: "UAE Region Support Services",
        key: "",
        index: 23,
        img: "",
        type: "list",
        htmlData: "",
        discription: [
          "Accounting",
          "Corporate Tax Registration & filing",
          "VAT Registration & filing",
          "Budgeting & Forecasting",
          "Finalisation of Accounts & Financials",
        ],
      },
    ];
    return data.map((item, index) => {
      return (
        <div
          className="service-block-one col-12 col-lg-6 text-left"
          key={index}
        >
          <div className="service">
            <h2>{item.label}</h2>
            <div className="text">
              <ul className="mx-3">
                {item.type === "html" ? (
                  <div dangerouslySetInnerHTML={{ __html: item.htmlData }} />
                ) : (
                  item.discription.map((i, index) => (
                    <li className="" key={index}>
                      {i}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <>
        <Header />
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>Services</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li className="active">Services</li>
            </ul>
          </div>
        </section>
        {/* <!--End Banner Section --> */}

        {/* <!-- Portfolio Details --> */}
        <div className="portfolio-details">
          <div className="auto-container">
            <div className="top-content">
              <div className="image-box text-center">
                <img
                  src={require("../../assets/images/resource/service.jpg")}
                  alt="TDS Return"
                  width="500px"
                  height="200px"
                />
              </div>
            </div>

            <div className="sec-title">
              <div className="sub-title">Services</div>
              <h1>
                Navigate Every Obstacle:
                <br />
                Find the Right Service for Your Business Goals.
              </h1>
            </div>
            <section className="style-two">
              <div className="auto-container">
                <div className="row">{this.renderservicer()}</div>
              </div>
            </section>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
export default Service;
