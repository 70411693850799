import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Footer from '../layout/footer'
import HomeSlider1 from './../element/home-slider1';
import PortfolioFilter1 from './../element/portfolio-filter1';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');
const authorbg1 = require('./../../assets/images/background/image-1.jpg');
// const videobg = require('./../../assets/images/background/image-2.jpg');
const teambg = require('./../../assets/images/background/image-3.jpg');
const newsbg = require('./../../assets/images/background/image-4.jpg');
class Onepage extends Component {


    render() {
        return (
            <>

                {/* <!--Search Popup--> */}
                <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>

                <HomeSlider1 />
                

                {/* <!-- Welcome Setion --> */}
                <section className="welcome-section pt-0">
                    <div className="auto-container">
                        <div className="wrapper-box">
                            <div className="row m-0">
                                {/* <!-- Welcome Block One --> */}
                                <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="inner-box">
                                        <div className="icon-box"><span className="flaticon-analysis"></span></div>
                                        <h2>Business Analysis</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </div>
                                        <div className="read-more"><Link to={'/#'}>Read More</Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="inner-box" style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}>
                                        <div className="icon-box"><span className="flaticon-tax"></span></div>
                                        <h2>Save Your Tax</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </div>
                                        <div className="read-more"><Link to={'/#'}>Read More</Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="inner-box">
                                        <div className="icon-box"><span className="flaticon-work-1"></span></div>
                                        <h2>Business Analysis</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore. </div>
                                        <div className="read-more"><Link to={'/#'}>Read More</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* <!-- About Section --> */}
                <section className="about-section" id="about">
                    <div className="auto-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-wrapper">
                                    <div className="image-one">
                                        <img  src={require('../../assets/images/resource/image-2.jpg')}  alt=""/>
                                    </div>
                                    <div className="image-two wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="caption">F.</div>
                                        <div className="image-outer"><img  src={require('../../assets/images/resource/image-3.jpg')}  alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <div className="sub-title">About Us</div>
                                        <h2>We Work With <br/>You To Address</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
                                    </div>
                                    <div className="row">
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-1.png')} alt=""/></div>
                                                <h5>Phone Number</h5>
                                                <h2>+897 6765 754</h2>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-2.png')} alt=""/></div>
                                                <h5>Email Address</h5>
                                                <h2>info@webmail.com</h2>
                                            </div>
                                        </div>
                                    </div>
                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Services Section --> */}
                <section className="services-section" id="service">
                    <div className="auto-container">
                        <div className="wrapper-box">
                            <div className="left-column">
                                <div className="services-content">
                                    <div className="sec-title light">
                                        <div className="sub-title">Our Services</div>
                                        <h2>What Actually We <br/>Do Here.</h2>
                                    </div>
                                    <div className="icon-box">
                                        <div className="icon wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/icons/icon-3.png')} alt=""/></div>
                                        <h2>Capital Market</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                    </div>
                                    <div className="icon-box">
                                        <div className="icon wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/icons/icon-4.png')} alt=""/></div>
                                        <h2>Insurance & Planning</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt=""/></div>
                                        <h2>Financial Strategy</h2>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                    </div>
                                </div>                        
                            </div>
                            <div className="right-column">
                                {/* <!-- Fact Counter --> */}
                                <div className="fact-counter">
                                    <div className="border-box">
                                        <div className="border_top"></div>
                                        <div className="border_bottom"></div>
                                        <div className="border_middile"></div>
                                    </div>
                                    <div className="row">

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-6.png')} alt="" /></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={90}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">Business Monitoring</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-7.png')} alt="" /></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={71}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">Business Growth</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-8.png')} alt="" /></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={33}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">Financial Plan</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-9.png')} alt="" /></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={42}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">Finance Consulting</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-10.png')} alt="" /></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={69}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">Data Analysis</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-11.png')} alt="" /></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={10}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">business Strategy</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="author-box" style={{ backgroundImage: "url(" + authorbg1 + ")" }}>
                                    <div className="author-info">
                                        <div className="author-thumb"><img src={require('../../assets/images/resource/author-1.jpg')} alt=""/></div>
                                        <h2>Rosalina D. William</h2>
                                        <div className="designation">Founder</div>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod..</div>
                                    </div>
                                    <div className="signature"><img src={require('../../assets/images/resource/signature.png')} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Gallery Section --> */}
                <PortfolioFilter1 />

                {/* <!-- Why Choose Us Section --> */}
                <section className="why-chooseus-section">
                    <div className="auto-container">
                        <div className="row align-items-center">                
                            <div className="col-lg-6">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <div className="sub-title">Why Choose Us</div>
                                        <h2>Why Should You <br/>Choose Us ?</h2>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                            <Nav.Item>
                                                <Nav.Link  eventKey="first"><span>Our Mission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link  eventKey="second"><span>Our Vission</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><span>Our Value</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="tabs-content">
                                            <Tab.Pane eventKey="first" className="fadeInUp animated">
                                                <div className="clearfix">
                                                    <div className="image"><img  src={require('../../assets/images/resource/image-4.jpg')}  alt=""/></div>
                                                    <div className="text"><p>Lorem ipsum dolor sit amet, consectetur adipisici ng elit, sed do eiusmod tempor incididunt ut labo re et dolore magna aliqua. Ut enim ad minim veni am, quis nostrud exercitation ullamco.</p><p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error</p></div>
                                                </div>                                              
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="fadeInUp animated">
                                                <div className="clearfix">
                                                    <div className="image"><img  src={require('../../assets/images/resource/image-4.jpg')}  alt=""/></div>
                                                    <div className="text"><p>Lorem ipsum dolor sit amet, consectetur adipisici ng elit, sed do eiusmod tempor incididunt ut labo re et dolore magna aliqua. Ut enim ad minim veni am, quis nostrud exercitation ullamco.</p><p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error</p></div>
                                                </div>                                            
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className="fadeInUp animated">
                                                <div className="clearfix">
                                                    <div className="image"><img  src={require('../../assets/images/resource/image-4.jpg')}  alt=""/></div>
                                                    <div className="text"><p>Lorem ipsum dolor sit amet, consectetur adipisici ng elit, sed do eiusmod tempor incididunt ut labo re et dolore magna aliqua. Ut enim ad minim veni am, quis nostrud exercitation ullamco.</p><p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error</p></div>
                                                </div>                                               
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-wrapper">
                                    <div className="image-one"><img src={require('../../assets/images/resource/image-6.jpg')} alt=""/></div>
                                    <div className="image-two"><img src={require('../../assets/images/resource/image-5.jpg')} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Team Section --> */}
                <section className="team-section" id="team" style={{ backgroundImage: "url(" + teambg + ")" }}>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">Our Team</div>
                            <h2>Leadership Team</h2>
                        </div>
                        <div className="row">
                            {/* <!-- Team Block One --> */}
                            <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt=""/></div>
                                    <div className="content">
                                        <div className="designation">Founder</div>
                                        <h3>Rosalina D. William</h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt=""/></div>
                                    <div className="content">
                                        <div className="designation">CEO</div>
                                        <h3>Velavos H. DesuJa</h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt=""/></div>
                                    <div className="content">
                                        <div className="designation">Developer</div>
                                        <h3>Abdur Rahman J.</h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Contact Section --> */}
                <section className="contact-section" id="contact">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="sec-title">
                                    <div className="sub-title">Write Here</div>
                                    <h2>Get In Touch</h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div className="contact-form">
                                    <form method="post" action="http://azim.commonsupport.com/Finandox/sendemail.php" id="contact-form">
                                        <div className="row clearfix">                                    
                                            <div className="col-md-12 form-group">
                                                <label for="name">Enter your name</label>
                                                <input type="text" name="username" id="name" placeholder="Enter name here......" required=""/>
                                                <i className="fas fa-user"></i>
                                            </div>
                                            
                                            <div className="col-md-12 form-group">
                                                <label for="email">Enter your email</label>
                                                <input type="email" name="email" id="email" placeholder="Enter email here......" required=""/>
                                                <i className="fas fa-envelope"></i>
                                            </div>
                    
                                            <div className="col-md-12 form-group">
                                                <label for="message">Enter your message</label>
                                                <textarea name="message" id="message" placeholder="Enter message here......"></textarea>
                                                <i className="fas fa-edit"></i>
                                            </div>
                    
                                            <div className="col-md-12 form-group">
                                                <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">Get In Touch</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-title">
                                    <div className="sub-title">Get Us Here</div>
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="contact-info">
                                    <div className="border-box">
                                        <div className="border_top"></div>
                                        <div className="border_middile"></div>
                                    </div>
                                    <div className="row">
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-email-6"></span></div>
                                                <h3>Email Address</h3>
                                                <ul>
                                                    <li><Link to={'/mailto:info@webmail.com'}>info@webmail.com</Link></li>
                                                    <li><Link to={'/mailto:jobs@exampleco.com'}>jobs@exampleco.com</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-call-1"></span></div>
                                                <h3>Phone Number</h3>
                                                <ul>
                                                    <li><Link to={'/tel:+8976765654654'}>+897 676 5654 654</Link></li>
                                                    <li><Link to={'/tel:+908(097)56476576'}>+908(097) 564 765 76</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-location"></span></div>
                                                <h3>Office Address</h3>
                                                <ul>
                                                    <li>12/A, Romania City Town Hall <br/>New Joursey, UK</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-worldwide"></span></div>
                                                <h3>Web Connect</h3>
                                                <ul>
                                                    <li><Link to={'/http://example.com/'}>example.com</Link></li>
                                                    <li><Link to={'/http://jobs.example.com/'}>jobs.example.com</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                                
                    </div>
                </section>

                {/* <!-- News Section --> */}
                <section className="news-section">
                    <div className="sec-bg" style={{ backgroundImage: "url(" + newsbg + ")" }}></div>
                    <div className="auto-container">
                        <div className="sec-title text-center light">
                            <div className="sub-title">News</div>
                            <h2>News From Resource</h2>
                        </div>
                        <div className="row">
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-1.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-2.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-3.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">Business</div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>By Admin</Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>UX is best solution for your <br/>business & make noise.</Link></h3>
                                        <div className="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Award Section --> */}
                <section className="award-section pt-0">
                    <div className="year">2020</div>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">Awards</div>
                            <h2>Get Awards</h2>
                        </div>
                        <div className="award-image">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-1.png')} alt=""/></div>
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-2.png')} alt=""/></div>
                                </div>
                                <div className="col-lg-4 order-lg-2">
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-3.png')} alt=""/></div>
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-4.png')} alt=""/></div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image wow zoomIn" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-5.png')} alt=""/></div>
                                </div>
                                
                            </div>
                        </div>                
                        <div className="feature-image">
                            <div className="image-one"><img src={require('../../assets/images/resource/image-7.jpg')} alt=""/></div>
                            <div className="image-two"><img src={require('../../assets/images/resource/image-8.jpg')} alt=""/></div>
                            <div className="image-three"><img src={require('../../assets/images/resource/image-9.jpg')} alt=""/></div>
                        </div>
                    </div>
                </section>


                <Footer/>
            </>
        )
    }
}
export default Onepage;