import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
const aboutbg = require("./../../assets/images/background/image-11.jpg");

class StartABusiness extends Component {
  renderService = () => {
    return this.ourService.map((item, index) => {
      return (
        <div className="col-lg-6" key={index}>
          <div className="icon-box pl-0">
            {/* <div className="icon">
              <img
                src={require("../../assets/images/icons/icon-3.png")}
                alt=""
              />
            </div> */}
            <h2>{item.label}</h2>
            <div className="text">
              <div dangerouslySetInnerHTML={{ __html: item.discription }} />
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <>
        <Header />
        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn">
            <span className="flaticon-cancel"></span>
          </div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form
                method="post"
                action="http://azim.commonsupport.com/Finandox/index.html"
              >
                <div className="form-group">
                  <fieldset>
                    <input
                      type="search"
                      className="form-control"
                      name="search-input"
                      value=""
                      placeholder="Search Here"
                      required
                    />
                    <input
                      type="submit"
                      value="Search Now!"
                      className="theme-btn"
                    />
                  </fieldset>
                </div>
              </form>
              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li>
                  <Link to={"/#"}>Finance</Link>
                </li>
                <li>
                  <Link to={"/#"}>Idea</Link>
                </li>
                <li>
                  <Link to={"/#"}>Service</Link>
                </li>
                <li>
                  <Link to={"/#"}>Growth</Link>
                </li>
                <li>
                  <Link to={"/#"}>Plan</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1> Start A Business</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li className="active">Start A Business</li>
            </ul>
          </div>
        </section>
        <section className="container">
          <div className="row">
            <div className="col-12 col-lg-12 my-5">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <strong>
                      <Link to={"/for-indian-owners"}>For Indian Owners</Link>
                    </strong>
                  </h4>
                </div>
                <div className="col-12">
                  <div className="row m-3">
                    <div className="col-12 mt-2">
                      <strong>
                        <Link
                          to={"/for-indian-owners-options/one-person-company"}
                        >
                          One Person Company (OPC)
                        </Link>
                      </strong>
                    </div>
                    <div className="col-12 mt-2">
                      <strong>
                        <Link
                          to={
                            "/for-indian-owners-options/limited-liability-partnerships"
                          }
                        >
                          Limited Liability Partnerships (LLPs)
                        </Link>
                      </strong>
                    </div>
                    <div className="col-12 mt-2">
                      <strong>
                        <Link
                          to={
                            "/for-indian-owners-options/private-limited-company"
                          }
                        >
                          Private Limited Company
                        </Link>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4>
                    <strong>
                      <Link to={"/for-foreign-owners"}>For Foreign Owners</Link>
                    </strong>
                  </h4>
                </div>
                <div className="col-12">
                  <div className="row m-3">
                    <div className="col-12 mt-2">
                      <strong>
                        <Link to={"/for-foreign-owners/subsidiary-company"}>
                          Subsidiary Company
                        </Link>
                      </strong>
                    </div>
                    <div className="col-12 mt-2">
                      <strong>
                        <Link to={"/for-foreign-owners/branch-liaison-office"}>
                          Branch / Liaison Office
                        </Link>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}
export default StartABusiness;
