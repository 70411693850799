import React from "react";

const images = [
  {
    id: 1,
    name: "mountains",
    banking: true,
    label: "Accounts",
    imgUrl: require("../../assets/images/gallery/gallery-12.jpg"),
  },
  // {
  //   id: 2,
  //   name: "lights",
  //   banking: false,
  //   label: "Accounts",
  //   imgUrl: require("../../assets/images/gallery/gallery-15.jpg"),
  // },
  {
    id: 3,
    name: "forest",
    insurance: true,
    label: "Taxation Consulting",
    imgUrl: require("../../assets/images/gallery/gallery-13.jpg"),
  },
  {
    id: 4,
    name: "retro",
    insurance: true,
    label: "Payroll Service",
    imgUrl: require("../../assets/images/gallery/gallery-14.jpg"),
  },
  {
    id: 5,
    name: "fast",
    insurance: true,
    label: "Business Registration",
    imgUrl: require("../../assets/images/gallery/gallery-16.jpg"),
  },
  {
    id: 6,
    name: "classic",
    insurance: true,
    label: "Audit",
    imgUrl: require("../../assets/images/gallery/gallery-17.jpg"),
  },
  {
    id: 7,
    name: "girl",
    family: true,
    label: "International Taxation Compliance",
   
    imgUrl: require("../../assets/images/gallery/gallery-11.jpg"),
  },
];

const PortfolioFilter1 = (props) => {
  return (
    <>
      <section className="gallery-section">
        <div className="sortable-masonry">
          <div className="auto-container">
            <div className="row m-0 justify-content-md-between align-items-center">
              <div className="sec-title">
                <div className="sub-title">Portfolio</div>
                <h2>Our Works</h2>
              </div>
              {/* <!--Filter--> */}
              {/* <div className="filters text-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                <ul className="filter-tabs filter-btns">
                    <li className="active filter" onClick={() => setState({ ...state, filterKey: "NONE" })}><span>Financial</span></li>
                    <li className="filter" onClick={() => setState({ ...state, filterKey: "BANKING" })}><span>Banking</span></li>
                    <li className="filter" onClick={() => setState({ ...state, filterKey: "INSURANCE" })}><span>Insurance </span></li>
                    <li className="filter" onClick={() => setState({ ...state, filterKey: "FAMILY" })}><span>Family</span></li>
                    <li className="filter" onClick={() => setState({ ...state, filterKey: "BUSINESS" })}><span>Business</span></li>
                </ul>
            </div> */}
            </div>
          </div>
          <div className="auto-container">
            <div className="items-container row">
              {images.map((image) => (
                <div
                  className="col-lg-3 col-md-6 gallery-block-one"
                  key={image.id}
                >
                  <div className="inner-box">
                    <div className="image">
                      <img src={image.imgUrl} alt={image.label} />
                    </div>
                    <div className="caption-title">
                      {/* <h5></h5> */}
                      <h3>{image.label}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioFilter1;
