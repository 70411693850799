import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header6 from '../layout/header6';
import Footer3 from '../layout/footer3';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');
const authorbg1 = require('./../../assets/images/background/image-1.jpg');
// const videobg = require('./../../assets/images/background/image-2.jpg');
const teambg = require('./../../assets/images/background/image-3.jpg');
const newsbg = require('./../../assets/images/background/image-4.jpg');

class RtlHome extends Component {


    render() {
        return (
            <>
                <Header6/>

                {/* <!--Search Popup--> */}
                <div id="search-popup" className="search-popup">
                    <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                    <div className="popup-inner">
                        <div className="overlay-layer"></div>
                        <div className="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div className="form-group">
                                    <fieldset>
                                        <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" className="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul className="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>

                {/* <!-- Welcome Setion --> */}
                <section className="welcome-section pt-0">
                    <div className="auto-container">
                        <div className="wrapper-box">
                            <div className="row m-0">
                                {/* <!-- Welcome Block One --> */}
                                <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="inner-box">
                                        <div className="icon-box"><span className="flaticon-analysis"></span></div>
                                        <h2>تحليل الأعمال  </h2>
                                        <div className="text">غينيا واستمر العصبة ضرب قد. وباءت الأمريكي الأوربيين هو به،, هو العالم، الثقيلة بال. مع وايرلندا الأوروبيّون كان, قد بحق أسابيع العظمى واعتلاء.  </div>
                                        <div className="read-more"><Link to={'/#'}>اقرأ أكثر  </Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="inner-box" style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}>
                                        <div className="icon-box"><span className="flaticon-tax"></span></div>
                                        <h2>حفظ الضرائب الخاصة بك  </h2>
                                        <div className="text">غينيا واستمر العصبة ضرب قد. وباءت الأمريكي الأوربيين هو به،, هو العالم، الثقيلة بال. مع وايرلندا الأوروبيّون كان, قد بحق أسابيع العظمى واعتلاء. </div>
                                        <div className="read-more"><Link to={'/#'}>اقرأ أكثر  </Link></div>
                                    </div>
                                </div>
                                {/* <!-- Welcome Block One --> */}
                                <div className="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div className="inner-box">
                                        <div className="icon-box"><span className="flaticon-work-1"></span></div>
                                        <h2>تحليل الأعمال  </h2>
                                        <div className="text">غينيا واستمر العصبة ضرب قد. وباءت الأمريكي الأوربيين هو به،, هو العالم، الثقيلة بال. مع وايرلندا الأوروبيّون كان, قد بحق أسابيع العظمى واعتلاء. </div>
                                        <div className="read-more"><Link to={'/#'}>اقرأ أكثر  </Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                {/* <!-- About Section --> */}
                <section className="about-section">
                    <div className="auto-container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-wrapper">
                                    <div className="image-one">
                                    <img  src={require('../../assets/images/resource/image-2.jpg')}  alt=""/>
                                    </div>
                                    <div className="image-two wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="caption">F.</div>
                                        <div className="image-outer"><img  src={require('../../assets/images/resource/image-3.jpg')}  alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <div className="sub-title">معلومات عنا  </div>
                                        <h2>نحن نعمل معكم لمعالجة  </h2>
                                        <div className="text">ركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. ركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                    <div className="row">
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-1.png')} alt=""/></div>
                                                <h5>رقم الهاتف  </h5>
                                                <h2>+897 6765 754</h2>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><img src={require('../../assets/images/icons/icon-2.png')} alt=""/></div>
                                                <h5>عنوان البريد الإلكتروني  </h5>
                                                <h2>info@webmail.com</h2>
                                            </div>
                                        </div>
                                    </div>
                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Services Section --> */}
                <section className="services-section">
                    <div className="auto-container">
                        <div className="wrapper-box">
                            <div className="left-column">
                                <div className="services-content">
                                    <div className="sec-title light">
                                        <div className="sub-title">خدماتنا  </div>
                                        <h2>في الواقع ما نفعله هنا. </h2>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/icon-3.png')} alt=""/></div>
                                        <h2>سوق رأس المال  </h2>
                                        <div className="text">ركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/icon-4.png')} alt=""/></div>
                                        <h2>التأمين والتخطيط  </h2>
                                        <div className="text">ركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                    <div className="icon-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div className="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt=""/></div>
                                        <h2>الاستراتيجية المالية  </h2>
                                        <div className="text">ركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>                        
                            </div>
                            <div className="right-column">
                                {/* <!-- Fact Counter --> */}
                                <div className="fact-counter">
                                    <div className="border-box">
                                        <div className="border_top"></div>
                                        <div className="border_bottom"></div>
                                        <div className="border_middile"></div>
                                    </div>
                                    <div className="row">

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-6.png')} alt=""/></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={90}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">رصد الأعمال  </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-7.png')} alt=""/></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={71}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">رازدهار العمل  </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-8.png')} alt=""/></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={33}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">خطة مالية  </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-9.png')} alt=""/></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={42}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">استشارات مالية  </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-10.png')} alt=""/></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={69}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">تحليل البيانات  </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {/* <!--Column--> */}
                                        <div className="column counter-column col-md-6">
                                            <div className="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                                <div className="content">
                                                    <div className="icon"><img src={require('../../assets/images/icons/icon-11.png')} alt=""/></div>
                                                    <div className="count-outer count-box">
                                                        <CountUp
                                                            end={10}
                                                            redraw={true}
                                                        >
                                                            {({ countUpRef, start }) => (
                                                                <VisibilitySensor
                                                                    onChange={start}
                                                                    delayedCall
                                                                >
                                                                    <span className="count-text" ref={countUpRef} />
                                                                </VisibilitySensor>
                                                            )}
                                                        </CountUp>
                                                        <span>%</span>
                                                    </div>
                                                    <div className="counter-title">استراتيجية العمل  </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="author-box" style={{ backgroundImage: "url(" + authorbg1 + ")" }}>
                                    <div className="author-info">
                                        <div className="author-thumb"><img src={require('../../assets/images/resource/author-1.jpg')} alt=""/></div>
                                        <h2>رحمة الله  </h2>
                                        <div className="designation">مؤسس   </div>
                                        <div className="text">ركتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                    <div className="signature"><img src={require('../../assets/images/resource/signature.png')} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Gallery Section --> */}
                <section className="gallery-section">
                    <div className="sortable-masonry">

                        <div className="auto-container">
                            <div className="row m-0 justify-content-md-between align-items-center">
                                <div className="sec-title order-2">
                                    <div className="sub-title">محفظة  </div>
                                    <h2>أعمالنا  </h2>
                                </div>
                                {/* <!--Filter--> */}
                                <div className="filters text-center">
                                    <ul className="filter-tabs filter-btns">
                                        <li className="active filter" data-role="button" data-filter=".all"><span>الأمور المالية  </span></li>
                                        <li className="filter" data-role="button" data-filter=".category-1"><span>الخدمات المصرفية  </span></li>
                                        <li className="filter" data-role="button" data-filter=".category-2"><span>تأمين   </span></li>
                                        <li className="filter" data-role="button" data-filter=".category-3"><span>عائلة  </span></li>
                                        <li className="filter" data-role="button" data-filter=".category-4"><span>اعمال  </span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="auto-container">
                            <div className="items-container row">
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-10.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-10.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-3 category-4">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-11.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-11.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-1 category-4">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-12.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-12.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-3 category-2">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-13.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-13.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-3 category-2 category-5">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-14.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-14.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-1 category-4">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-15.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-15.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-3 category-2">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-16.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-16.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Gallery Block One --> */}
                                <div className="col-lg-3 col-md-6 gallery-block-one all category-3 category-2 category-5">
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={require('../../assets/images/gallery/gallery-17.jpg')} alt=""/>
                                        </div>
                                        <div className="caption-title">
                                            <h5>المالية  </h5>
                                            <h3><Link to={'/#'}>ميراندا هيليكس بار  </Link></h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-17.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End block --> */}
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Why Choose Us Section --> */}
                <section className="why-chooseus-section">
                    <div className="auto-container">
                        <div className="row align-items-center">                
                            <div className="col-lg-6">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <div className="sub-title">لماذا أخترتنا  </div>
                                        <h2>لماذا يجب عليك إختيارنا ؟  </h2>
                                    </div>
                                    <Tab.Container defaultActiveKey="first">
                                        <Nav variant="pills" className="nav nav-tabs tab-btn-style-one">
                                            <Nav.Item>
                                                <Nav.Link  eventKey="first"><span>مهمتنا</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link  eventKey="second"><span>رؤيتنا</span></Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><span>رؤيتنا</span></Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content className="tabs-content">
                                            <Tab.Pane eventKey="first" className="fadeInUp animated">
                                                <div className="clearfix">
                                                    <div className="image"><img  src={require('../../assets/images/resource/image-4.jpg')}  alt=""/></div>
                                                <div className="text"><p>Lكتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p><p>كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p></div>
                                                </div>                                              
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="fadeInUp animated">
                                                <div className="clearfix">
                                                    <div className="image"><img  src={require('../../assets/images/resource/image-4.jpg')}  alt=""/></div>
                                                <div className="text"><p>Lكتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p><p>كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p></div>
                                                </div>                                            
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third" className="fadeInUp animated">
                                                <div className="clearfix">
                                                    <div className="image"><img  src={require('../../assets/images/resource/image-4.jpg')}  alt=""/></div>
                                                <div className="text"><p>Lكتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p><p>كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن.</p></div>
                                                </div>                                               
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>                                            
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-wrapper">
                                    <div className="image-one"><img src={require('../../assets/images/resource/image-6.jpg')} alt=""/></div>
                                    <div className="image-two"><img src={require('../../assets/images/resource/image-5.jpg')} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Team Section --> */}
                <section className="team-section" style={{ backgroundImage: "url(" + teambg + ")" }}>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">فريقنا  </div>
                            <h2> فريق القيادة </h2>
                        </div>
                        <div className="row">
                            {/* <!-- Team Block One --> */}
                            <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-1.jpg')} alt=""/></div>
                                    <div className="content">
                                        <div className="designation">مؤسس  </div>
                                        <h3>رحمة الله  </h3>
                                        <div className="text">كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-2.jpg')} alt=""/></div>
                                    <div className="content">
                                        <div className="designation">المدير التنفيذي  </div>
                                        <h3>فيلافوس هـ. دي  </h3>
                                        <div className="text">تنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Team Block One --> */}
                            <div className="col-lg-4 team-block-one wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><img src={require('../../assets/images/resource/team-3.jpg')} alt=""/></div>
                                    <div className="content">
                                        <div className="designation">مطور  </div>
                                        <h3>عبد الرحمن ج. </h3>
                                        <div className="text">تنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Contact Section --> */}
                <section className="contact-section">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="sec-title">
                                    <div className="sub-title">اكتب هنا </div>
                                    <h2>ابقى على تواصل  </h2>
                                </div>
                                {/* <!-- Contact Form--> */}
                                <div className="contact-form">
                                    <form method="post" action="http://azim.commonsupport.com/Finandox/sendemail.php" id="contact-form">
                                        <div className="row clearfix">                                    
                                            <div className="col-md-12 form-group">
                                                <label for="name">أدخل أسمك  </label>
                                                <input type="text" name="username" id="name" placeholder="Enter name here......" required=""/>
                                                <i className="fas fa-user"></i>
                                            </div>
                                            
                                            <div className="col-md-12 form-group">
                                                <label for="email">أدخل بريدك الالكتروني  </label>
                                                <input type="email" name="email" id="email" placeholder="Enter email here......" required=""/>
                                                <i className="fas fa-envelope"></i>
                                            </div>
                    
                                            <div className="col-md-12 form-group">
                                                <label for="message">أدخل رسالتك  </label>
                                                <textarea name="message" id="message" placeholder="Enter message here......"></textarea>
                                                <i className="fas fa-edit"></i>
                                            </div>
                    
                                            <div className="col-md-12 form-group">
                                                <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">ابقى على تواصل  </span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-title">
                                    <div className="sub-title">احصل لنا هنا  </div>
                                    <h2>اتصل بنا  </h2>
                                </div>
                                <div className="contact-info">
                                    <div className="border-box">
                                        <div className="border_top"></div>
                                        <div className="border_middile"></div>
                                    </div>
                                    <div className="row">
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-email-6"></span></div>
                                                <h3>عنوان البريد الإلكتروني</h3>
                                                <ul>
                                                    <li><Link to={'/mailto:info@webmail.com'}>info@webmail.com</Link></li>
                                                    <li><Link to={'/mailto:jobs@exampleco.com'}>jobs@exampleco.com</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-call-1"></span></div>
                                                <h3>رقم الهاتف</h3>
                                                <ul>
                                                    <li><Link to={'/tel:+8976765654654'}>+897 676 5654 654</Link></li>
                                                    <li><Link to={'/tel:+908(097)56476576'}>+908(097) 564 765 76</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-location"></span></div>
                                                <h3>عنوان المكتب</h3>
                                                <ul>
                                                    <li>12/A, Romania City Town Hall <br/>New Joursey, UK</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="info-column col-md-6">
                                            <div className="icon-box">
                                                <div className="icon"><span className="flaticon-worldwide"></span></div>
                                                <h3>شبكة الاتصال  </h3>
                                                <ul>
                                                    <li><Link to={'/http://example.com/'}>example.com</Link></li>
                                                    <li><Link to={'/http://jobs.example.com/'}>jobs.example.com</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                                
                    </div>
                </section>

                {/* <!-- News Section --> */}
                <section className="news-section">
                    <div className="sec-bg" style={{ backgroundImage: "url(" + newsbg + ")" }}></div>
                    <div className="auto-container">
                        <div className="sec-title text-center light">
                            <div className="sub-title">أخبار  </div>
                            <h2>أخبار من الموارد </h2>
                        </div>
                        <div className="row">
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-1.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">اعمال  </div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>بواسطة الادارية  </Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>هو أفضل حل لعملك التجاري وجعل الضوضاء. </Link></h3>
                                        <div className="text">كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-2.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">اعمال  </div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>واسطة الادارية </Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>هو أفضل حل لعملك التجاري وجعل الضوضاء. </Link></h3>
                                        <div className="text">كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- News Block One --> */}
                            <div className="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <div className="inner-box">
                                    <div className="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-3.jpg')} alt=""/></Link></div>
                                    <div className="lower-content">
                                        <div className="category">اعمال  </div>
                                        <ul className="post-meta">
                                            <li><Link to={'/#'}><i className="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                            <li><Link to={'/#'}><i className="far fa-user"></i>واسطة الادارية  </Link></li>
                                        </ul>
                                        <h3><Link to={'/blog-details'}>هو أفضل حل لعملك التجاري وجعل الضوضاء. </Link></h3>
                                        <div className="text">كتنا هي واحدة من الشركات الرائدة في العالم في مجال الاستشارات الإدارية. الحصول على اتصال هنا في اسرع وقت ممكن. </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Award Section --> */}
                <section className="award-section pt-0">
                    <div className="year">2020</div>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <div className="sub-title">جوائز  </div>
                            <h2>الحصول على جوائز  </h2>
                        </div>
                        <div className="award-image">
                            <div className="row align-items-center">
                                <div className="col-lg-4">
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-1.png')} alt=""/></div>
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-2.png')} alt=""/></div>
                                </div>
                                <div className="col-lg-4 order-lg-2">
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-3.png')} alt=""/></div>
                                    <div className="image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-4.png')} alt=""/></div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image wow zoomIn" data-wow-delay="200ms" data-wow-duration="1200ms"><img src={require('../../assets/images/resource/award-5.png')} alt=""/></div>
                                </div>
                                
                            </div>
                        </div>                
                        <div className="feature-image">
                            <div className="image-one"><img src={require('../../assets/images/resource/image-7.jpg')} alt=""/></div>
                            <div className="image-two"><img src={require('../../assets/images/resource/image-8.jpg')} alt=""/></div>
                            <div className="image-three"><img src={require('../../assets/images/resource/image-9.jpg')} alt=""/></div>
                        </div>
                    </div>
                </section>



                <Footer3/>
            </>
        )
    }
}
export default RtlHome;